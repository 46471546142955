import CommunitySocialLinks from "./CommunitySocialLinks";

const HighWidthDevicesFooterNav = () =>{ 
  return(
    <nav className="mindai-section-footer-nav">
    <ul className="mindai-section--align_center mindai-section-list site-footer"> 
      <li className="mindai-section-footer-item">
          <div className="mindai-section-footer-group-grid">
            <div className="mindai-seciton-about-group">
              <div className="mindai-section-footer--nav--items-container">
                <h2  style={{'textAlign':'center', 'fontSize':'24px'}}>About</h2> 
                    <ol className="mindai-section-footer--nav--item mindai-section-footer--nav-items--vertical">
                      <li>
                        <a className='mindai-section-link' href="https://officialmindai.com/#about">About Us</a> 
                      </li> 
                    </ol>
              </div>
            </div>
            <div>
              <h2 style={{'textAlign':'center', 'fontSize':'24px'}}>Privacy & Legal</h2>
              <ol className="mindai-section-footer--nav--item mindai-section-footer--nav-items--vertical">
                        <li>
                          <a className='mindai-section-link' href="https://officialmindai.com/privacy">Privacy Policy</a> 
                        </li>
                        <li>
                          <a  className='mindai-section-link' href="https://officialmindai.com/cookies-policy">Cookies Policy</a> 
                       </li> 
              </ol>
            </div>

            <div className='mindai-section-social_summary'>
                <h2 style={{'textAlign':'center', 'fontSize':'24px'}}>Community</h2>
                <CommunitySocialLinks />
            </div>
            <div className="mindai-section-disclaimer-description">
              <h2 style={{'textAlign':'center', 'fontSize':'24px'}}>Disclaimer</h2>
              <span >
                Cryptocurrency may be unregulated in your jurisdiction.
                The value of cryptocurrencies may go down as well as up. 
                Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
              </span>
            </div>
          </div>
      </li>
      <li className="mindai-section-footer-item">
        <div className="mindai-section-footer-item--center">
          <div className="mindai-section-footer-item-flex">
              <a href="https://officialmindai.com/#about"
              title="Copyright 2022 MindAI"
              className="mindai-section-link">
                MindAI © {new Date().getFullYear()} - VAT 12602300969
              </a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  )
}

export default HighWidthDevicesFooterNav;