// import DiscordLogo from "../../Logos/DiscordLogo";
// import FacebookLogo from "../../Logos/FacebookLogo";
import InstagramLogo from "../../Logos/InstagramLogo";
// import RedditLogo from "../../Logos/RedditLogo";
import TelegramLogo from "../../Logos/TelegramLogo";
import TwitterLogo from "../../Logos/TwitterLogo"; 

const CommunitySocialLinks =() =>{
    return(
        <div className='mindai-section-social-icon-resume'>
            {/* <a className='mindai-section-social-icon-resume-item' href="/"> 
                    <DiscordLogo /> 
            </a>
            <a className='mindai-section-social-icon-resume-item' href="/"> 
                    <FacebookLogo /> 
            </a> */}
            <a className='mindai-section-social-icon-resume-item' target='_blank' rel="noreferrer" href="https://www.instagram.com/officialmindai"> 
                    <InstagramLogo /> 
            </a>
            {/* <a className='mindai-section-social-icon-resume-item' href="/"> 
                    <RedditLogo /> 
            </a> */}
            <a className='mindai-section-social-icon-resume-item' target='_blank' rel="noreferrer" href="https://t.me/MindAIOfficial"> 
                    <TelegramLogo /> 
            </a>
            <a className='mindai-section-social-icon-resume-item' target='_blank'  rel="noreferrer" href="https://twitter.com/officialmindai"> 
                    <TwitterLogo /> 
            </a>
        </div>
    )
}


export default CommunitySocialLinks;