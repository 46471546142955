// import DiscordLogo from "../../Logos/DiscordLogo";
// import FacebookLogo from "../../Logos/FacebookLogo";
import InstagramLogo from "../../Logos/InstagramLogo";
// import RedditLogo from "../../Logos/RedditLogo";
import TelegramLogo from "../../Logos/TelegramLogo";
import TwitterLogo from "../../Logos/TwitterLogo"; 

const SmallWidthDevicesFooterNav = () =>{
  // var __BASE_PATH__='';
  // if (typeof window !== 'undefined') {
  //   __BASE_PATH__= window.location.protocol + '//' +  window.location.host ;  
  // } 
  return( 
    <nav className="mindai-section-footer-nav">
    <ul className="mindai-section--align_center mindai-section-list mindai-section-site-footer"> 
      <li className="mindai-section-footer-item">
          <div className="mindai-section-footer-group-flex">
            <div className="mindai-section-about-group">
            <div className="mindai-section-footer--nav--items-container">
               <h2 style={{'textAlign':'center'}}>About</h2>
                
                  <ol className="mindai-section-footer--nav--item mindai-section-footer--nav-items--vertical">
                    <li>
                      <a className='mindai-section-link' href="https://officialmindai.com/#about">About Us</a> 
                    </li>
                  </ol>
            </div>
            </div>
            <div className="">
              <h2 style={{'textAlign':'center'}}>Privacy & Legal</h2>
              <ol className="mindai-section-footer--nav--item mindai-section-footer--nav-items--vertical">
                    <li>
                      <a  className='mindai-section-link' href="https://officialmindai.com/privacy">Privacy Policy</a> 
                    </li> 
                    <li>
                      <a  className='mindai-section-link' href="https://officialmindai.com/cookies-policy">Cookies Policy</a> 
                    </li> 
                </ol>
            </div>

            <div className='mindai-section-social_summary'>
                <h2 style={{'textAlign':'center'}}>Community</h2>
                <div className='mindai-section-social-icon-resume_small-screen'>
                    {/* <a className='mindai-section-social-icon-resume-item-resume_small-screen' href="/"> 
                            <DiscordLogo /> 
                    </a>
                    <a className='mindai-section-social-icon-resume-item-resume_small-screen' href="/"> 
                            <FacebookLogo /> 
                    </a> */}
                    <a className='mindai-section-social-icon-resume-item-resume_small-screen' rel="noreferrer" target='_blank' href="https://www.instagram.com/officialmindai"> 
                            <InstagramLogo /> 
                    </a>
                    {/* <a className='mindai-section-social-icon-resume-item-resume_small-screen' href="/"> 
                            <RedditLogo /> 
                    </a> */}
                    <a className='mindai-section-social-icon-resume-item-resume_small-screen' 
                      rel="noreferrer" target='_blank' href="https://t.me/MindAIOfficial"> 
                            <TelegramLogo /> 
                    </a>
                    <a className='mindai-section-social-icon-resume-item-resume_small-screen' 
                      rel="noreferrer" target='_blank' href="https://twitter.com/officialmindai"> 
                            <TwitterLogo /> 
                    </a>
                </div>
            </div>
            <div className="mindai-section-link">
              <h2 style={{'textAlign':'center'}}>Disclaimer</h2>
              <span className="mindai-section-link">
                Cryptocurrency may be unregulated in your jurisdiction.
                The value of cryptocurrencies may go down as well as up. 
                Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
              </span>
            </div>
          </div>
      </li>
      <li className="mindai-section-footer-item">
        <div className="mindai-section-footer-item--center">
          <div className="mindai-section-footer-item-flex">
              <a href="https://officialmindai.com/#about"
              title="Copyright 2022 MindAI"
              className="mindai-section-link"
              >
                MindAI © {new Date().getFullYear()} - VAT 12602300969
              </a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  )

}


export default SmallWidthDevicesFooterNav;