const InstagramLogo= () =>{

    return(
    
    <svg className='mindai-section-icons'  version="1.1"  xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24" enableBackground="new 0 0 32 32" 
         height='24px'
         width='24px'
         fill="none"
         >
        <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M17.5 6.51L17.51 6.49889" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
    
    )
    
    }
    
    export default InstagramLogo;