const ExtendedNavLogo   =() =>{

    return(

        <svg  className='mindai-section-icon mindai-section-icon-logo-wordmark' version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6096 2160" width="2096" height="2160">

            <g transform="translate(-900.500000,3360.000000) scale(0.200000,-0.200000)"
            fill="#000000" stroke="none">
            
            <path d="M5960 13045 l0 -545 1365 0 1365 0 29 28 c16 15 73 77 127 137 53 61
            255 282 448 493 226 246 352 392 354 408 l3 24 -1845 0 -1846 0 0 -545z"/>
            <path d="M11950 11697 l0 -1893 521 -512 c317 -311 529 -512 540 -512 19 0 19
            54 19 2405 l0 2405 -540 0 -540 0 0 -1893z"/>
            <path d="M13712 13561 c3 -26 78 -85 703 -543 l700 -513 1140 0 1140 0 3 543
            2 542 -1846 0 -1845 0 3 -29z"/>
            <path d="M17950 11667 l0 -1922 490 -483 490 -482 50 0 50 0 0 2405 0 2405
            -540 0 -540 0 0 -1923z"/>
            <path d="M19725 13571 c11 -10 342 -254 735 -542 l715 -524 838 -3 837 -2 0
            545 0 545 -1572 0 c-1539 0 -1572 0 -1553 -19z"/>
            <path d="M23401 13538 c1 -55 5 -50 11 15 2 20 0 37 -4 37 -4 0 -7 -24 -7 -52z"/>
            <path d="M25221 13586 c2 -2 335 -246 739 -542 l735 -539 619 -3 618 -2 42 42
            c42 43 754 820 834 909 36 41 42 53 42 93 l0 46 -1817 0 c-999 0 -1814 -2
            -1812 -4z"/>
            <path d="M29400 13550 c0 -22 2 -40 4 -40 2 0 6 18 8 40 2 22 0 40 -4 40 -5 0
            -8 -18 -8 -40z"/>
            <path d="M31160 11697 l0 -1892 519 -510 c286 -281 529 -511 540 -513 l21 -3
            0 2405 0 2406 -540 0 -540 0 0 -1893z"/>
            <path d="M23937 12869 l-537 -631 0 -914 0 -914 540 0 540 0 0 1545 c0 850 -1
            1545 -3 1545 -1 0 -244 -284 -540 -631z"/>
            <path d="M29937 12869 l-537 -631 0 -1212 0 -1211 524 -515 c289 -283 532
            -516 540 -518 15 -3 16 218 16 2357 0 1299 -1 2361 -3 2361 -1 0 -244 -284
            -540 -631z"/>
            <path d="M10738 12679 l-538 -631 1 -1087 0 -1086 537 -527 537 -527 3 1119
            c1 615 1 1625 0 2244 l-3 1126 -537 -631z"/>
            <path d="M5960 10857 l0 -1103 494 -487 494 -487 46 0 46 0 0 1590 0 1590
            -540 0 -540 0 0 -1103z"/>
            <path d="M8067 11953 c-4 -3 -7 -491 -7 -1083 l0 -1077 514 -506 c412 -407
            519 -507 540 -507 l26 0 0 1590 0 1590 -533 0 c-294 0 -537 -3 -540 -7z"/>
            <path d="M13710 10872 l0 -1088 509 -502 c451 -444 513 -502 540 -502 l31 0 0
            1590 0 1590 -540 0 -540 0 0 -1088z"/>
            <path d="M19710 10370 l0 -1590 540 0 540 0 0 1590 0 1590 -540 0 -540 0 0
            -1590z"/>
            <path d="M25160 10846 l0 -1113 484 -477 484 -476 56 0 56 0 0 1590 0 1590
            -540 0 -540 0 0 -1114z"/>
            <path d="M26760 11211 c0 -14 194 -162 712 -540 l711 -521 334 0 333 0 0 540
            0 540 -1045 0 c-1027 0 -1045 0 -1045 -19z"/>
            <path d="M21340 9320 l0 -540 1033 0 1032 0 540 540 540 540 -1573 0 -1572 0
            0 -540z"/>
 
            </g>
        </svg>
    )

}


export default ExtendedNavLogo;