import Logo from "../Logos/Logo";
import "../../css/Loader/Loader.css";

const Loader = () => {

  return(
    <div className="loader-full"> 
      <div className="loader-container">
        <Logo />
        <div className="loader" /> 
      </div> 
    </div>
    
  )


}



export default Loader;