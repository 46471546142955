import React from 'react';
import ReactDOM from 'react-dom/client'; 
import MndshellwithBanner from './components/js/Shell/mnd-shell-with-banner'
import App from './App';
import Footer from './components/js/Footer/Footer';
import {BrowserRouter} from 'react-router-dom';
import {  
  EthereumClient,
  w3mConnectors,
  w3mProvider, 
} from "@web3modal/ethereum"; 
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {  mainnet } from "wagmi/chains";   
import './index.css';
import { Web3Modal } from '@web3modal/react';
// import './components/css/Main-MindAI/MainMindAI.css'

const projectId = process.env.REACT_APP_PROJECT_ID; 
const chains = [mainnet]; 
 
 
const { publicClient, webSocketPublicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1, chains, projectId }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);


// Renders on element with id: root
const root = ReactDOM.createRoot(document.getElementById('root'));
 

// Renders here
root.render(
  <React.StrictMode> 
     <BrowserRouter>
     <WagmiConfig config={wagmiConfig}>
      <MndshellwithBanner />  
        <App /> 
        <Footer /> 
      </WagmiConfig> 
     </BrowserRouter> 
     <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
  </React.StrictMode>

);
 
