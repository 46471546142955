const Logo = () => { 
return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    viewBox="30 200 1200 1000"
    preserveAspectRatio="xMidYMid meet"
   
    className="logo-loader"
    style={{'display': 'block', 'margin':'auto'}}
    >

        
        <g transform="translate(-150.000000,1580.000000) scale(0.150000,-0.150000)"
        stroke="none">
        <path d="M2268 8263 c-599 -2 -1088 -6 -1088 -11 0 -11 820 -826 857 -851 l31
        -21 2349 0 2348 0 401 403 c220 221 413 419 428 440 l27 37 -553 0 c-304 0
        -1264 2 -2133 3 -869 2 -2069 2 -2667 0z"/>
        <path d="M8645 7873 c-99 -94 -283 -275 -409 -401 l-229 -230 7 -1844 7 -1843
        352 -353 c311 -312 450 -442 472 -442 8 0 -5 4805 -14 5089 l-6 195 -180 -171z"/>
        <path d="M2435 6848 c-2 -7 -6 -986 -8 -2174 l-4 -2162 126 -124 c703 -697
        873 -863 881 -866 5 -2 19 16 32 40 l22 43 4 2606 4 2606 -30 22 c-30 21 -35
        21 -526 21 -388 0 -498 -3 -501 -12z"/>
        <path d="M5210 4968 l0 -1872 284 -286 c157 -157 402 -402 545 -545 l261 -259
        0 2417 0 2417 -545 0 -545 0 0 -1872z"/>
        </g>
     
    </svg>

    )
    
}


export default Logo;