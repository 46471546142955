export const COOKIE_MODAL_DATE_VALUE = (days) =>{

    var date = new Date();
    return date.addDays(days)
  
}
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}