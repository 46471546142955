import "../../css/Footer/footer.css"
import _useWindowSize from '../common_functions/windowsSize'
import HighWidthDevicesFooterNav from "./FooterComponents/HighWidthDevicesNav";
import SmallWidthDevicesFooterNav from "./FooterComponents/SmallWidthDevicesNav";


const Footer = () =>{
  const size = _useWindowSize()


  return(
    <footer aria-label="Site footer" 
      className="mindai-section-shell-footer mindai-section-site-footer
      mindai-section-footer mindai-layout-footer theme--marketing -scrim--white" 
     style={{'display':'block'}}
    >
      {size.width >600 ? 
        (<HighWidthDevicesFooterNav />) :
        (<SmallWidthDevicesFooterNav />)}

    </footer>
  )

}



export default Footer;