import MainImage from '../../../../../themes/custom/mindai_frontend/assets/images/MX-Social.webp'
import _useWindowSize from '../../../common_functions/windowsSize';

const UnderGridMainImage =() =>{

    const size = _useWindowSize();
    
    return(
        <div className='images_assets'>
            <div className='images_ isactive images-exterior'>
                <div role='presentation' className='images_assets--section is-active' 
                    style={{"position": "relative", "left": "0px", "top": "0px", "transform": "scale(1)", "touchAction":"none"}}>
                    <svg version='1.1' 
                        xmlns="http://www.w3.org/2000/svg"
                        className='asset-loader__svg-container asset-loader__ group--main-content--asset'
                        style={{'overflow':'hidden', 'cursor':'inherit','opacity':'1', 'transform':'scale(1.3)','height':(size.height)}}
                        >
                        <image height="100%"
                            width="100%" 
                            // className='main-overview-presale-under-grid image' 
                            href={MainImage}
                            alt='The MindAI Coin Allocations'
                            preserveAspectRatio="xMidYMid meet"

                            style={{'objectFit': 'contain'}}
                        >
                        </image>
                    </svg>
                </div>
            </div>
        </div>
    )
}


export default UnderGridMainImage;