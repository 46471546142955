const TwitterLogo= () =>{

    return(
    
    <svg className='mindai-section-icons'  version="1.1"  xmlns="http://www.w3.org/2000/svg"
         viewBox="-2 -1 36 36" enableBackground="new 0 0 36 36" 
         height='24px'
         width='24px'
         fill="currentColor"
         >
        <g>
	        <path fill="#393c41" d="M16-0.034C7.159-0.034-0.035,7.158-0.035,16S7.159,32.034,16,32.034S32.035,24.842,32.035,16
		    S24.841-0.034,16-0.034z M16,30.966C7.748,30.966,1.035,24.252,1.035,16S7.748,1.034,16,1.034S30.965,7.748,30.965,16
            S24.252,30.966,16,30.966z"/>
	        <path fill="#393c41" d="M24.794,11.046c0.11-0.204,0.202-0.419,0.273-0.643c0.067-0.212-0.002-0.442-0.176-0.581
            c-0.173-0.138-0.415-0.154-0.605-0.042c-0.513,0.305-1.065,0.53-1.646,0.673c-0.698-0.631-1.615-0.987-2.562-0.987
            c-2.105,0-3.817,1.712-3.817,3.815c0,0.053,0.001,0.105,0.003,0.158c-2.235-0.279-4.298-1.415-5.735-3.178
            c-0.11-0.136-0.28-0.207-0.456-0.195c-0.175,0.014-0.332,0.112-0.42,0.265c-0.338,0.58-0.517,1.243-0.517,1.919
            c0,0.64,0.159,1.256,0.451,1.802c-0.074,0.012-0.147,0.039-0.214,0.078c-0.163,0.097-0.264,0.292-0.264,0.481
            c0,1.135,0.505,2.177,1.319,2.885c-0.012,0.011-0.023,0.023-0.035,0.035c-0.125,0.142-0.166,0.338-0.108,0.518
            c0.351,1.094,1.164,1.946,2.184,2.365c-1.041,0.515-2.25,0.716-3.406,0.579c-0.244-0.022-0.481,0.116-0.566,0.352
            c-0.083,0.234,0.005,0.495,0.215,0.63c1.592,1.02,3.431,1.559,5.319,1.559c6.18,0,9.874-5.02,9.874-9.873
            c0-0.055,0-0.109-0.001-0.164c0.597-0.466,1.115-1.023,1.541-1.66c0.132-0.197,0.118-0.459-0.035-0.641
            C25.259,11.017,25.01,10.955,24.794,11.046z M23.049,12.801c-0.147,0.106-0.231,0.279-0.222,0.461
            c0.006,0.132,0.009,0.266,0.009,0.398c0,4.328-3.293,8.805-8.804,8.805c-0.989,0-1.964-0.166-2.887-0.488
            c1.105-0.211,2.141-0.682,3.045-1.39c0.178-0.14,0.249-0.376,0.177-0.591c-0.072-0.214-0.271-0.36-0.497-0.364
            c-0.891-0.018-1.699-0.461-2.194-1.162c0.253-0.017,0.501-0.059,0.742-0.124c0.239-0.065,0.401-0.286,0.393-0.534
            c-0.008-0.247-0.186-0.457-0.429-0.506c-1.003-0.201-1.79-0.943-2.081-1.905c0.266,0.067,0.539,0.106,0.812,0.115
            c0.235-0.001,0.453-0.145,0.526-0.371s-0.013-0.475-0.212-0.607c-0.765-0.512-1.222-1.366-1.222-2.287
            c0-0.223,0.027-0.443,0.079-0.658c1.731,1.775,4.08,2.847,6.568,2.972c0.176,0.001,0.33-0.062,0.437-0.191s0.148-0.3,0.11-0.464
            c-0.047-0.203-0.071-0.414-0.071-0.627c0-1.515,1.233-2.747,2.748-2.747c0.757,0,1.488,0.316,2.005,0.868
            c0.126,0.135,0.312,0.194,0.494,0.159c0.165-0.033,0.328-0.071,0.489-0.115c-0.072,0.054-0.147,0.104-0.226,0.151
            c-0.216,0.129-0.312,0.393-0.231,0.631s0.324,0.394,0.569,0.357c0.061-0.007,0.121-0.015,0.182-0.023
            C23.259,12.646,23.155,12.725,23.049,12.801z"/>
    </g>
  </svg>
    
    )
    
    }
    
    export default TwitterLogo;