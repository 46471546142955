import "../../css/ErrorNotFound/404.css"

const NotFound = () =>{
  return(
    <div className="main-error"> 
      <main role="main" >
       <section className="panel"> 
       <div className="error-container">
            <div className="error-code">
              404
            </div>
            <div className="error-text">
              Page not found
            </div>
        </div>
       </section>
      </main>
    </div>
  )

}


export default NotFound;