import '../../css/Navbar/nav.css'
import '../../css/Modal/modal.css'
import { Link } from 'react-router-dom'; 
import ExtendedNavLogo from '../Logos/ExtendedNavLogo'
import SectionMenu from './NavbarComponents/SectionMenu';
import _useWindowSize from '../common_functions/windowsSize'; 

const Navbar =   () =>{
 
  const size =  _useWindowSize() 
  const _modalHandler  =() =>{

    var _modal = document.getElementById('menu-modal');
    // _modal.style.display  = "block"; 
    if (typeof _modal.showModal === "function") {
      _modal.showModal();
      _modal.ariaHidden="false";
    } else {
      _modal.value = "Sorry, the <dialog> API is not supported by this browser.";
    } 
    var _closeMenu = document.getElementsByClassName('mindai-section-modal-close')[0]; 
     _closeMenu.onclick= function() {  
      _modal.close()
     _modal.ariaHidden="true";
    }  
  
  }
  const handleMenuDataReturned =(handleMenuData) =>{
    if(handleMenuData){
      var _modal = document.getElementById('menu-modal'); 
      _modal.close()
      _modal.ariaHidden="true"; 
    }
  }

   return(
    <div className="mindai-section-global-menu">
      <header 
        className="mindai-section-site-header mindai-section-fade-in"
        style={{'--mindai-section-animate-backdrop-transition':'opacity 0.5s ease, visibility 0s 0.5s',
            '--mindai-section-animate-backdrop-opacity':'0',
            '--mindai-section-animate-backdrop-visibility':'hidden',
            '--mindai-section-animate-backdrop-top':'12px',
            '--mindai-section-animate-backdrop-left':'678px',
            '--mindai-section-animate-backdrop-height':'32px',
            '--mindai-section-animate-backdrop-width':'110px'}}>

          {/* Official Logo */}
          <h1 className='site-logo dccea7722760'>
            <Link  to="https://officialmindai.com/" className='logo-link '  aria-label="MindAI Logo">
                <ExtendedNavLogo /> 

            </Link>
          </h1>

          {size.width > 1024? (
            <> 
            <ol className="mindai-section-site-nav-items 
                            mindai-section-align--center
                            mindai-section--product-name" >
              {/* <li>
                  <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href={`/how-to-buy`}> 
                  <span className='mindai-section-site-nav-item-text'> 
                     How to buy
                  </span>
                </a>  
              </li>   */}
              {/* <li>
                <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href='https://officialmindai.com/how-to-buy'> 
                  <span className='mindai-section-site-nav-item-text'> 
                      How to buy
                  </span>
                </a>
              </li> */}
              <li>
                  <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                                     rel='noreferrer' target='_blank' 
                  href='https://officialmindai.com/_mindai_wp_1.pdf'> 
                  <span className='mindai-section-site-nav-item-text'> 
                     Whitepaper
                  </span>
                </a>  
              </li>  
               {/* <li>
                  <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href='https://officialmindai.com/TMC'> 
                  <span className='mindai-section-site-nav-item-text'> 
                     TMC
                  </span>
                </a>  
              </li>                 */}
              {/* <li>
                <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href={`${__BASE_PATH__}#about`}> 
                  <span className='mindai-section-site-nav-item-text'> 
                      About Us
                  </span>
                </a>
              </li> */}
              {/* <li>
                <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href='https://officialmindai.com/#roadmap'> 
                  <span className='mindai-section-site-nav-item-text'> 
                      Roadmap
                  </span>
                </a>
              </li> */}
              {/* <li>
                <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href='https://officialmindai.com/#ecosystem'> 
                  <span className='mindai-section-site-nav-item-text'> 
                      Ecosystem
                  </span>
                </a>
              </li> */}
             {/* <li>
                <a type='button'   
                        className="mindai-section-site-nav-item
                                    mindai-section-animate--backdrop
                                     mindai-section--product-name"
                  href='https://officialmindai.com/announcement'> 
                  <span className='mindai-section-site-nav-item-text'> 
                      Announcements
                  </span>
                </a>
              </li> */}
            </ol>  
            <ol className="mindai-section-site-nav-items
                        mindai-section-align--end 
                        mindai-section--product-name" >
              <li>

                <button type='button'   
                  className="c0c0ef22304d e60bc4acb94" id="ln_button"
                onClick={_modalHandler}> 
                  <span className='mindai-section-site-nav-item-text'> 
                      Menu
                  </span>
                </button>
              </li>
            </ol>
               </>
            ):(
            <ol className="e46069942 aebe8bd3ea" >
              <li>
                <button type='button'   
                className="c0c0ef22304d e60bc4acb94" id="ln_button"
                onClick={_modalHandler}> 
                  <span className='site-nav-item-text'> 
                      Menu
                  </span>
                </button>
              </li>
            </ol> )} 

        
      </header> 
      {/* <dialog aria-hidden='true' id='menu-modal' 
        className='mindai-section-modal
          mindai-section-modal--sheet-right
          mindai-section-site-header-modal
          mindai-section-modal--sheet-small
          mindai-section-scrim--white'>
        <div className='mindai-section-modal-header'>
          <button aria-label='Close Modal'
                  className='mindai-section-modal-close'
                  type='button'>
                  <svg className='icon9cfb menumodal-close-icon' viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                    <path d="M208.48535,191.51465a12.0001,12.0001,0,0,1-16.9707,16.9707L128,144.9707,64.48535,208.48535a12.0001,12.0001,0,0,1-16.9707-16.9707L111.0293,128,47.51465,64.48535a12.0001,12.0001,0,0,1,16.9707-16.9707L128,111.0293l63.51465-63.51465a12.0001,12.0001,0,0,1,16.9707,16.9707L144.9707,128Z"/>
                  </svg>
            </button>
        </div>
        <SectionMenu /> 
      </dialog> */}
      <dialog aria-hidden='true' id='menu-modal' 
          className='menumodal menumodal--sheet-right site-header-menumodal menumodal--sheet-small abf01c--white'>
        <div className='menumodal-header'>
          <button aria-label='Close Modal'
                  className='mindai-section-modal-close'
                  type='button'>
                  <svg className='icon9cfb menumodal-close-icon' viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                    <path d="M208.48535,191.51465a12.0001,12.0001,0,0,1-16.9707,16.9707L128,144.9707,64.48535,208.48535a12.0001,12.0001,0,0,1-16.9707-16.9707L111.0293,128,47.51465,64.48535a12.0001,12.0001,0,0,1,16.9707-16.9707L128,111.0293l63.51465-63.51465a12.0001,12.0001,0,0,1,16.9707,16.9707L144.9707,128Z"/>
                  </svg>
            </button>
        </div>
        <SectionMenu handleMenuData={handleMenuDataReturned}/> 
      </dialog>
    </div>
  )
}

export default Navbar;