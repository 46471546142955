import MainImage from '../../../../../themes/custom/mindai_frontend/assets/images/MX-Social.webp'
import _useWindowSize from '../../../common_functions/windowsSize';
const NoGridMainImage =() =>{

    const size = _useWindowSize();

    return(
        <div className='main-placeholder' style={{'height': '100%'}}>
        <div role='presentation' className='main-overview image container'>
            <svg version='1.1' 
                xmlns="http://www.w3.org/2000/svg"
                className='asset-loader__svg-container asset-loader__ group--main-content--asset'
                style={{'overflow':'hidden',
                        'cursor':'inherit',
                        'opacity':'1',
                        'transform': 'scale(1)',
                        'height': (isNaN(size.height) ? 450 :(size.height/2) ) }}
                >
                
                <image width="100%" height="100%"  
                href={MainImage}
                alt='The MindAI Coin Allocations'
                preserveAspectRatio="xMidYMid meet"
                ></image>

            </svg>
        </div> 
    </div>
    )
}


export default NoGridMainImage;