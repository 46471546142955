import Navbar from "../Navbar/navbar" 
import '../../css/Navbar/nav.css'



const MndshellwithBanner = ()=>{
  return (
    <>
       <div id="mindai-section-site-header-wrapper" className='mindai-section-header-content mindai-section-site-header-wrapper'>
            <Navbar /> 
        </div>      
        {/* <Buy /> */}
    </>
  
  )
}


export default MndshellwithBanner;