import _useWindowSize from '../common_functions/windowsSize'
import React, { Suspense, useEffect }  from 'react';
// import CookiesModal from '../Cookies/Cookies';
import { getStrictlyNecessaryCookies } from '../common_functions/CookiesSettings';
import UnderGridMainImage from '../buy_components/BuyGridMainComponents/UnderGridImage/UnderGridMainImage';
import NoGridMainImage from '../buy_components/NoGridMainComponents/NoGridImage/NoGridMainImage';
import '../../css/Buy/buy.css'
import '../../css/Buy/buy-nogrid.css'
import { ethers } from 'ethers';
import Loader from '../Loader/loader';
import ReactGA from "react-ga4"; 
import { CookiesTypeIdsEn } from '../common_functions/CookiesSettings';
const UserTypeGrid = React.lazy(()=> import('../user_type_components/user_type_grid'));
const UserType = React.lazy(()=> import('../user_type_components/user_type'));
const MetaMaskConnected = React.lazy(()=> import('../buy_components/Metamask/metamask-connected')); 
const WalletConnectConnected = React.lazy(()=> import('../buy_components/WalletConnect/walletconnect_connected'));
const UnderGridTokenHeaderEstimatedEnd = React.lazy(()=> import('../buy_components/BuyGridMainComponents/UnderGridTokenHeader/UnderGridTokenHeaderEstimatedEnd'));
const UnderGridTokenSummary = React.lazy(()=> import('../buy_components/BuyGridMainComponents/UnderGridSummary/UnderGridTokenSummary'));
// const UnderGridProgressiveBar = React.lazy(()=> import('../buy_components/BuyGridMainComponents/UnderGridProgressiveBar/UnderGridProgressiveBar'));
const NoGridTokenHeader = React.lazy(()=> import('../buy_components/NoGridMainComponents/NoGridTokenHeader/NoGridTokenHeader'));
const NoGridTokenSummary = React.lazy(()=> import('../buy_components/NoGridMainComponents/NoGridTokenSummary/NoGridTokenSummary')); 
// const NoGridProgressBar = React.lazy(()=> import('../buy_components/NoGridMainComponents/NoGridProgressBar/NoGridProgressBar'));

// import '../../css/Main-MindAI/MainMindAI.css'


const Buy =() =>{ 
    const [walletData, setWalletData] = React.useState({});
    const _walletStatesComponent = (_walletData) => { 
        setWalletData(_walletData);
    };
    const _toReturnStatus = (_walletData) =>{
        setWalletData(_walletData);
    }
    //To check the size of window and hide navbar components
    const size = _useWindowSize();
     
    const [cookieState, setCookieState] = React.useState({
        hasAcceptedStrictly: false,
        hasAccepted: false,
        hasRejected: false,
        hasCustomized: false
    }); 
    useEffect(()=>{ 
        function _customPerformanceCookies(cookiesData){ 
            if (CookiesTypeIdsEn.length>0) {       
                if (cookiesData.includes(CookiesTypeIdsEn[3].ShortName+':true')) {  //Performance Cookies   
                    ReactGA.initialize('G-VJY1YVRHX4');
                } 
            } 
        }
        function _updateCookiesState(){
            var cookie =getStrictlyNecessaryCookies(1); 
            if(cookie !== undefined){          
                setCookieState({
                    hasAcceptedStrictly : true,
                    hasAccepted: false,
                    hasRejected: true
                }) 
            }
    
            cookie =getStrictlyNecessaryCookies(2); 
            if(cookie !== undefined){          
                setCookieState({
                    hasAcceptedStrictly : true,
                    hasAccepted: false,
                    hasRejected: true
                }) 
                ReactGA.initialize('G-VJY1YVRHX4'); 
            }
    
            cookie =getStrictlyNecessaryCookies(3); 
            if(cookie !== undefined){          
                setCookieState({
                    hasAcceptedStrictly : true,
                    hasAccepted: false,
                    hasRejected: true
                }) 
                _customPerformanceCookies(cookie)
            }
            cookie =getStrictlyNecessaryCookies(4); 
            if(cookie !== undefined){  
               // console.log('debug: cookie 4', cookie );
                if (typeof window.ethereum !== 'undefined') { 
                    if (window.ethereum.isMetaMask){ 
                        // console.log('debug: cookie address', window.ethereum.selectedAddress );
                        (async() =>{
                            const account =  await window.ethereum.request({ method: 'eth_accounts' }) ; 
                          //  console.log('debug: cookie address', account);
                            const provider = new ethers.providers.Web3Provider(window.ethereum)
                           // console.log('debug: cookie provider', provider );
                            setWalletData( {
                                Provider: provider, 
                                connected: true,
                                address : account!== null ? (account[0] !== null ? account[0] : account): account,
                                state : 3,
                                errorRes:'',
                                errorHeader: '',
                                errormessage: '',
                                isReturningUser:false
                            })
                        })()
                 
                    }
                }
            }
        } 
        _updateCookiesState()
    }, []) //cookieState.hasAccepted, cookieState.hasRejected



    return(
        <main className='section-main-content-buy'>
        {size.width>1024 ? ( 
            <section role='none' className='main-overview-presale-grid gr-layout'>
                <UnderGridMainImage />
                {/* {(cookieState.hasAcceptedStrictly !==true && 
                    cookieState.hasAccepted !==true && 
                    cookieState.hasRejected !==true ) ? (<CookiesModal /> ): (<></>)} */}
                <Suspense fallback={<Loader />}>
                    <div className='main-overview-presale-under-grid group-section'>
                        <UnderGridTokenHeaderEstimatedEnd />
                        <UnderGridTokenSummary />
                        {/* <UnderGridProgressiveBar /> */}
                        {walletData.state===3 ? (
                            <MetaMaskConnected _walletStates= {walletData} _toReturnStatus= {_toReturnStatus} />
                        ): walletData.state===4 ?(
                                <WalletConnectConnected _walletStates= {walletData} _toReturnStatus= {_toReturnStatus} />
                        ):(       <UserTypeGrid _walletStatesComponent ={_walletStatesComponent} /> 
                        )
                        }    
                    </div>
                </Suspense>
            </section>  
            ) : ( 
            <section role='none' id='overview' 
            className='main-overview-presale option-widget -no_horizontal_padding'>
                <Suspense fallback={<Loader />}>
                    <NoGridMainImage />
                    {/* {(cookieState.hasAcceptedStrictly !==true && 
                        cookieState.hasAccepted !==true && 
                        cookieState.hasRejected !==true ) ? (<CookiesModal /> ): (<></>)} */}
                    <NoGridTokenHeader />
                    <NoGridTokenSummary /> 
                    {/* <NoGridProgressBar /> */}
                    {walletData.state===3 ? (
                        <MetaMaskConnected _walletStates= {walletData} _toReturnStatus= {_toReturnStatus} />
                    ): walletData.state===4 ?(
                        <WalletConnectConnected _walletStates= {walletData} _toReturnStatus= {_toReturnStatus} />
                    ): ( 
                        <UserType _walletStatesComponent ={_walletStatesComponent} /> 
                    )
                    }
                </Suspense> 
            </section>
            )}
        </main>
    )
} 

export default Buy;