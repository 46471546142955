import {useRoutes} from 'react-router-dom';
 import Buy from './components/js/Buy/buy'
import NotFound from './components/js/NotFound/404';   
// import IT_IT from './components/js/it_IT/index/it_IT';

const App=() =>{
  let _routes = useRoutes([
   
    { path: '/', element: <Buy />} ,  
    {path : "/*", element: <NotFound />}
  ])
   
 

  return _routes
}



export default App