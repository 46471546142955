import Cookie from 'universal-cookie'
import { COOKIE_MODAL_REJECTED, COOKIE_MODAL_ACCEPTED, COOKIE_MODAL_CUSTOM, COOKIE_CONNECTED } from "./CookiesName";
import { COOKIE_MODAL_DATE_VALUE } from "./CookiesValue";

export const CookieStatus= {
    defaultStatus: 1,
    rejectedStatus:2,
    customizationStatus: 3,
    acceptedStatus: 4,
    undefinedStatus: 5,
    errorStatus: 0
}

export const CookiesTypeIdsEn =  [
        {
            ID: 1,
            Enabled: false,
            ShortName: 'strictcookies',
            Name: 'Strictly necessary cookies',   
            Description: 'These cookies are necessary for the site to function and cannot be deactivated in our systems.' +
                          'They are usually only set in response to actions you provoke that constitute a request for services,'+
                            'such as setting privacy preferences, logging in or filling out forms.' +
                            'You can set your browser to block or have warnings about these cookies,'+
                            'but as a result some parts of the site will not work.'+
                            'These cookies do not store personal information.',
            Types:[
                {
                    TypeID: 1,
                    TypeName: 'First party cookies' 
                } 
            ]
        },
        {
            ID: 2,
            Enabled: false,
            ShortName: 'funccookies',
            Name: 'Functionalities cookies',   
            Description: 'These cookies allow the site to provide advanced features and customization. They can be set by us or by third party providers whose services have been added to our pages. If you do not authorize these cookies, some or all of these services may not work properly.',
            Types:[
                {
                    TypeID: 1,
                    TypeName: 'First party cookies' 
                } 
            ]
        },
        {
            ID: 3,
            Enabled: false,
            ShortName: 'targcookies',
            Name: 'Targeting cookies',   
            Description: 'Targeting cookies may be set through our site by our advertising partners. They can be used by these third parties to build a profile of your interests based on the browsing information they collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not allow these cookies you will still see basic advertising on your browser that is generic and not based on your interests.',
            Types:[
                {
                    TypeID: 1,
                    TypeName: 'First party cookies' 
                } 
            ]
        },
        {
            ID: 4,
            Enabled: true,
            ShortName: 'perfcookies',
            Name: 'Performance cookies',   
            Description: 'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site and will not be able to monitor its performance.',
            Types:[
                {
                    TypeID: 1,
                    TypeName: 'First party cookies' 
                } 
            ]
        }
    ];

export const CookiesDates =   [ 
        {
            ID : CookiesTypeIdsEn[0].ID ?? NaN,
            Name: CookiesTypeIdsEn[0].Name ?? NaN,
            Duration: 364
        }     
]

 

export const setStrictlyNecessaryCookies =(number=1) =>{

    var __SET_COOKIES__ = new Cookie();
    var cookieName = ''
    var cookieValue = undefined
    switch (number) {
        case 1:        
            cookieName = COOKIE_MODAL_REJECTED;
            cookieValue = new Date(COOKIE_MODAL_DATE_VALUE(CookiesDates[0].Duration)); 
            __SET_COOKIES__.set(cookieName, cookieValue, { path: '/',expires: cookieValue }) 
         
            break
        case 2:

             cookieName =COOKIE_MODAL_ACCEPTED;
             cookieValue = new Date(COOKIE_MODAL_DATE_VALUE(CookiesDates[0].Duration));  
             __SET_COOKIES__.set(cookieName, cookieValue, { path: '/', expires: cookieValue })
             
             break
        case 3:
            cookieName = COOKIE_CONNECTED;
            cookieValue = '0x0'; 
            __SET_COOKIES__.set(cookieName, cookieValue, { path: '/',expires: new Date(COOKIE_MODAL_DATE_VALUE(CookiesDates[0].Duration))});
   

            break
        default:
            __SET_COOKIES__.set(cookieName, cookieValue, {
                expires: 1,
                secure: true,
                httpOnly:true,
                sameSite: 'strict',
                path: '/'
            });
            break
    }


}

export const setCustomizedCookies =(CUSTOMIZED_VALUE) =>{

    var __SET_COOKIES__ = new Cookie();
    var cookieName = ''
    var cookieValue = CUSTOMIZED_VALUE
     
    cookieName = COOKIE_MODAL_CUSTOM; 
    __SET_COOKIES__.set(cookieName, cookieValue, { path: '/',expires: new Date(COOKIE_MODAL_DATE_VALUE(CookiesDates[0].Duration)) }) 
        
}

export const getCustomizedCookies =() =>{

    var __GET_COOKIES__ = new Cookie();
    var  cookieName = COOKIE_MODAL_CUSTOM; 
    var cookieValue = '';
      
    cookieValue = __GET_COOKIES__.get(cookieName) ;
    //console.log(cookieValue);
 
}


export function  splittedCookies(cname, decoded_cookie){
    var name = cname + "="; 

    var carr = decoded_cookie.split(';');
    for(var i=0; i<carr.length;i++){
        var c = carr[i];
        while(c.charAt(0)===' '){
            c=c.substring(1);
        }
        if(c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getStrictlyNecessaryCookies =(number=0) =>{
    var __GET_COOKIES__ = new Cookie();
     var cookieName=''
     var cookie=''
    switch (number) {
        case 1:        
            cookieName = COOKIE_MODAL_REJECTED;  

            cookie =__GET_COOKIES__.get(cookieName); 
            return cookie
        case 2:     
            cookieName = COOKIE_MODAL_ACCEPTED;  

            cookie =__GET_COOKIES__.get(cookieName); 
            return cookie
        case 3:     
            cookieName = COOKIE_MODAL_CUSTOM;  

            cookie =__GET_COOKIES__.get(cookieName); 
            return cookie 
        case 4:     
            cookieName = COOKIE_CONNECTED;  

            cookie =__GET_COOKIES__.get(cookieName); 
            return cookie    
        default:
            break
    }
}
export const removeStrictlyNecessaryCookies =(number=0) =>{
    var _REMOVE_COOKIE = new Cookie();
     var cookieName=''
     var cookie=''
    switch (number) {
        case 1:        
            cookieName = COOKIE_MODAL_REJECTED;  

            cookie =_REMOVE_COOKIE.get(cookieName); 
            return cookie
        case 2:     
            cookieName = COOKIE_MODAL_ACCEPTED;  

            cookie =_REMOVE_COOKIE.get(cookieName); 
            return cookie
        case 3:     
            cookieName = COOKIE_MODAL_CUSTOM;  

            cookie =_REMOVE_COOKIE.get(cookieName); 
            break
        case 4:     
            cookieName = COOKIE_CONNECTED;   
            cookie =_REMOVE_COOKIE.remove(cookieName);   
            
            break
        default:
            break
    }
}
